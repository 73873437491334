import React, { Component } from 'react'
import flecha from '../../images/stats-icon-lg.png';

export class Notificaciones extends Component {

   
    render() {
      

      function contadores(s1,s2,s3) {
       
        
        return (
            <div className="about-sec-2">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="content-indicador">
                                        <p className="text-center"><img src={flecha} alt="Flecha"/></p>
                                        <h2>{s1.toFixed(3)}</h2>
                                        <h5>Volumen Acumulado M<sup>3</sup></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6"> 
                                    <div className="content-indicador">
                                        <p className="text-center"><img src={flecha} alt="Flecha"/></p>
                                        <h2>{(s3.toFixed(3))}</h2>
                                        <h5>Volumen Semanal M<sup>3</sup></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6"> 
                                    <div className="content-indicador">
                                        <p className="text-center"><img src={flecha} alt="Flecha"/></p>
                                        <h2>{(s2.toFixed(3))}</h2>
                                        <h5>Volumen Mensual M<sup>3</sup></h5>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
         
      )
        
      }

      return contadores(this.props.voldia, this.props.volmes, this.props.volsem);
        
    }
}

export default Notificaciones